import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import {
  addAnggota,
  addAnggotaBulk,
  deleteAnggota,
  downloadAnggota,
  fetcherAuth,
  updateAnggota,
  updateAnggotaBulk,
  uploadFotoAnggota,
} from '../../../services';
import Info from '../../../components/Info';
import Table from '../../components/Table';
import InputImage from '../../components/InputImage';
import Pagination from '../../components/Pagnation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';

function AddEditMember({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    name: '',
    addr: '',
    url_foto: '',
    sabukid: '',
    dojo: { id: '' },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: dataSabuk } = useSWR('sabuks', fetcherAuth);
  const { data: dataDojo } = useSWR('dojos?page=0&limit=999', fetcherAuth);

  useEffect(() => {
    editData && setFormData(editData);
  }, [editData]);

  const clearForm = () => {
    setFormData({
      name: '',
      addr: '',
      url_foto: '',
      sabukid: '',
      dojo: { id: '' },
    });
    setIsShow(false);
  };

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('name', formData.name);
    formDataObj.append('addr', formData.addr);
    formDataObj.append('sabukid', formData.sabukid);
    formDataObj.append('dojoid', formData.dojo.id);
    typeof formData.url_foto === 'object' && formDataObj.append('foto', formData.url_foto);

    try {
      const response = isEdit ? await updateAnggota(formDataObj, editData.id) : await addAnggota(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      clearForm();
    }
  };

  const isFormEmpty = () => {
    let result = false;

    const obj = {
      name: formData.name,
      // addr: String(formData.addr).trim(),
      sabukid: formData.sabukid,
      dojoid: formData.dojo.id,
    };

    // eslint-disable-next-line no-unused-vars
    for (const [_, value] of Object.entries(obj)) {
      if (!value) {
        result = true;
        break;
      }
    }

    return result;
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={clearForm}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Anggota</h1>
            <button type="button" className="btn-close" onClick={() => clearForm()}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  value={formData.name ? formData.name : ''}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="addr" className="form-label">
                  Address
                </label>
                <textarea
                  id="addr"
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={formData.addr ? formData.addr : ''}
                  onChange={(e) => setFormData({ ...formData, addr: e.target.value })}
                ></textarea>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="sabukid" className="form-label">
                  Sabuk
                </label>
                <select
                  className="form-select"
                  id="sabukid"
                  value={formData.sabukid ? formData.sabukid : ''}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      sabukid: e.target.value,
                    })
                  }
                >
                  <option value="">Pilih Sabuk</option>
                  {dataSabuk &&
                    dataSabuk.result.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.des} - {d.grade}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="dojoid" className="form-label">
                  Dojo
                </label>
                <select
                  className="form-select"
                  id="dojoid"
                  value={formData.dojo.id ? formData.dojo.id : ''}
                  onChange={(e) => setFormData({ ...formData, dojo: { id: e.target.value } })}
                >
                  <option value="">Pilih Dojo</option>
                  {dataDojo &&
                    dataDojo.result.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Photo</label>
                <InputImage
                  imgSrc={formData.url_foto ? formData.url_foto : ''}
                  name="url_foto"
                  changeInputImage={(e) => setFormData({ ...formData, url_foto: e.target.files[0] })}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => clearForm()}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const columns = ['No', 'Name', 'Address', 'Photo', 'Sabuk', 'Dojo', 'Action'];

function Row({ result, isDeleting, onIsShow, onIsEdit, onEditData, onDeleteRow, isLoading, isCode = false }) {
  return isLoading ? (
    <tr>
      <td colSpan="7">Loading...</td>
    </tr>
  ) : (
    result &&
      result.map((d) => (
        <tr key={isCode ? d.code : d.id}>
          <td>{d.no}</td>
          <td>{d.name}</td>
          <td>{d.addr}</td>
          <td>
            <img src={d.url_foto} alt="" width={80} />
          </td>
          <td>
            {d.sabuk && d.sabuk.des} - {d.sabuk && d.sabuk.grade}
          </td>
          <td>{d.dojo.name}</td>
          <td>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => {
                onIsShow(true);
                onIsEdit(true);
                onEditData(d);
              }}
            >
              Edit
            </button>
            &nbsp;
            <button
              disabled={isDeleting}
              onClick={() => onDeleteRow(isCode ? d.code : d.id)}
              className="btn btn-sm btn-danger"
              title="Delete"
            >
              Delete
            </button>
          </td>
        </tr>
      ))
  );
}

export default function MemberCMS() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);
  const [isUploadingPhotos, setIsUploadingPhotos] = useState(false);
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [photos, setPhotos] = useState(null);
  const [editData, setEditData] = useState(null);
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });

  // Pagination
  const itemsPerPage = 5;
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [url, setUrl] = useState(`anggotas?limit=${itemsPerPage}&page=${page}`);
  const { data, mutate, isLoading } = useSWR(url, fetcherAuth);
  const dataWC =
    data &&
    data.result.map((d, i) => {
      return { ...d, no: page * itemsPerPage + (i + 1) };
    });
  const currentItems = dataWC && dataWC;
  const pageCount = Math.ceil(data && data.totalRows / itemsPerPage);

  const handlePageClick = (event) => {
    setPage(event.selected);
    setUrl(`anggotas?limit=${itemsPerPage}&page=${event.selected}&search_name=${searchName}`);
  };

  const handleItemsSearch = () => {
    setPage(0);
    setUrl(`anggotas?limit=${itemsPerPage}&page=0&search_name=${searchName}`);
  };

  const deleteRow = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deleteAnggota(id);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data
          ? typeof res.response.data == 'string'
            ? res.response.data
            : res.response.data.msg
          : res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg || res.data.message });
    mutate();
  };

  const inputFile = useRef(null);
  const inputFile2 = useRef(null);
  const inputPhotos = useRef(null);

  const uploadBulk = async () => {
    if (file) {
      setIsUploading(true);
      const formDataObj = new FormData();
      formDataObj.append('excelFile', file);

      try {
        const response = await addAnggotaBulk(formDataObj);
        if (response) {
          setIsShow(false);
          handleSubmit(response);
          mutate();
        }
      } catch (err) {
        console.log(err);
        handleSubmit(err);
      } finally {
        setIsUploading(false);
        setFile(null);
        inputFile.current.value = '';
      }
    }
  };

  const uploadBulk2 = async () => {
    if (file2) {
      setIsUploading2(true);
      const formDataObj = new FormData();
      formDataObj.append('excelFile', file2);

      try {
        const response = await updateAnggotaBulk(formDataObj);
        if (response) {
          setIsShow(false);
          handleSubmit(response);
          mutate();
        }
      } catch (err) {
        console.log(err);
        handleSubmit(err);
      } finally {
        setIsUploading2(false);
        setFile2(null);
        inputFile2.current.value = '';
      }
    }
  };

  const uploadFoto = async () => {
    if (photos) {
      setIsUploadingPhotos(true);
      const formDataObj = new FormData();
      formDataObj.append('images', photos);

      try {
        const response = await uploadFotoAnggota(formDataObj);
        if (response) {
          setIsShow(false);
          handleSubmit(response);
          mutate();
        }
      } catch (err) {
        console.log(err);
        handleSubmit(err);
      } finally {
        setIsUploadingPhotos(false);
        setPhotos(null);
        inputPhotos.current.value = '';
      }
    }
  };

  return (
    <div>
      <h2>Anggota</h2>
      <hr />
      <div className="d-flex align-items-start justify-content-between">
        <button
          onClick={() => {
            setIsShow(true);
            setIsEdit(false);
            setEditData(null);
          }}
          type="button"
          className="btn btn-primary "
          title="Add Anggota"
        >
          Add
        </button>

        <div className="d-flex align-items-center gap-2">
          <input type="text" className="form-control" onChange={(e) => setSearchName(e.target.value)} />
          <button className="btn btn-warning" onClick={handleItemsSearch}>
            Search
          </button>
        </div>
      </div>
      <div className="my-2 w-50">
        <div className="d-flex align-items-center gap-2 mb-2">
          <input className="form-control" type="file" onChange={(e) => setFile(e.target.files[0])} ref={inputFile} />
          <button className="btn btn-info" onClick={uploadBulk} disabled={isUploading} style={{ width: '240px' }}>
            <FontAwesomeIcon icon={faUpload} />
            <span className="ms-2">Add Bulk</span>
          </button>
        </div>

        <div className="d-flex align-items-center gap-2 mb-2">
          <input className="form-control" type="file" onChange={(e) => setFile2(e.target.files[0])} ref={inputFile2} />
          <button className="btn btn-info" onClick={uploadBulk2} disabled={isUploading2} style={{ width: '240px' }}>
            <FontAwesomeIcon icon={faUpload} />
            <span className="ms-2">Update Bulk</span>
          </button>
        </div>

        <div className="d-flex align-items-center gap-2">
          <input
            className="form-control"
            type="file"
            onChange={(e) => setPhotos(e.target.files[0])}
            ref={inputPhotos}
            multiple
          />
          <button className="btn btn-info" onClick={uploadFoto} disabled={isUploadingPhotos} style={{ width: '240px' }}>
            <FontAwesomeIcon icon={faUpload} />
            <span className="ms-2">Upload Photos</span>
          </button>
        </div>
      </div>
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table
        columns={columns}
        children={
          <Row
            result={currentItems}
            isDeleting={isDeleting}
            onIsShow={setIsShow}
            onIsEdit={setIsEdit}
            onEditData={setEditData}
            onDeleteRow={deleteRow}
            isLoading={isLoading}
          />
        }
      />
      <div className="d-flex align-items-center justify-content-between">
        <Pagination pageCount={pageCount} onPageClick={handlePageClick} page={page} />
        <a title="Download" className="btn btn-danger" href={downloadAnggota} download={downloadAnggota}>
          <FontAwesomeIcon icon={faDownload} />
        </a>
      </div>
      <AddEditMember
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
